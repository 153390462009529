<template>
  <div class="article-header__wrapper">
    <a-link
      v-if="isCategoryVisible"
      :to="linkToCategoryPage"
      :trailing-slash="!isMigratedCategory"
      class="article-header__category-section font-sans semi-bold"
    >
      {{ category.name }}
    </a-link>
    <slot name="isSponsored">
      <div
        v-if="isSponsored"
        class="article-header__sponsored font-sans semi-bold"
      >
        Sponsored
      </div>
    </slot>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import mixins from '@/utils/mixins'
import {
  findMigratedCategorySlug,
  MIGRATED_CATEGORY_SLUG
} from 'enums/categories'
import { ROUTE_NAME } from 'enums/routes'

export default {
  name: 'AArticleHeader',
  mixins: [mixins.urlGenerators],
  props: {
    category: propValidator([PROP_TYPES.OBJECT], false),
    isSponsored: propValidator([PROP_TYPES.BOOLEAN], false, false),
    /* ToDo: isSessionWrap - may be useful for future implementation */
    isSessionWrap: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hideCategory: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  computed: {
    isCategoryVisible() {
      return this.category && !this.hideCategory
    },
    isMigratedCategory() {
      if (!this.category) return false

      const { slug } = this.$helper.processResponse(this.category)
      return findMigratedCategorySlug(slug)
    },
    isNewsCategory() {
      return (
        this.category.slug.toLowerCase() ===
        MIGRATED_CATEGORY_SLUG.NEWS.toLowerCase()
      )
    },
    linkToCategoryPage() {
      return this.isNewsCategory
        ? { name: ROUTE_NAME.ALL_NEWS }
        : this.generateCategoryPath(this.category)
    }
  }
}
</script>

<style lang="scss" scoped>
.article-header__wrapper {
  display: flex;

  @include mobile {
    font-size: 12px;
  }

  .article-header__category-section {
    margin-right: auto;
    color: $c--main;
  }

  .article-header__sponsored {
    margin-left: auto;
    color: $c--main;
  }
}
</style>
